import React, {Component,Fragment} from 'react';
import TopNavigation from "../components/TopNavigation/TopNavigation";
import PageTop from "../components/PageTop/PageTop";
import AboutPage from "../components/AboutPage/AboutPage";
import Footer from "../components/Footer/Footer";

class AboutPageDescription extends Component {

    componentDidMount() {
        window.scroll(0,0);
    }

    render() {
        return (
            <Fragment>
                <TopNavigation  title="About"/>
                <PageTop pageTitle="About GenCoder"/>
                <AboutPage/>
                <Footer/>
            </Fragment>
        );
    }
}

export default AboutPageDescription;