import React, {Component, Fragment} from 'react';
import '../../asset/css/custom.css';
import '../../asset/css/bootstrap.min.css';
import {Col, Container, Row, Button} from "react-bootstrap";
import AppUrl from "../../RestAPI/AppURL";
import RestClient from "../../RestAPI/RestClient";

class TopBanner extends Component {

    constructor(){
        super();
        this.state={
            title:'',
            subtitle:'',
            button:''
        }
    }

    componentDidMount() {
        RestClient.GetRequest(AppUrl.TopBanner).then(result=>{
            this.setState({title:result[0]['topbanner_title'],subtitle:result[0]['topbanner_subtitle'],button:result[0]['topbanner_contact_button']});
        }).catch(error=>{
            this.setState({title:'??????',subtitle:'??????',button:'??????'});
        });
    }

    render() {
        return (
            <Fragment>
                <Container fluid={true} className="topFixedBanner p-0">
                    <div className="topBannerOverlay">
                        <Container className="topContent text-center">
                            <Row>
                                <Col>
                                    <h1 className="topTitle">{this.state.title}</h1>
                                    <h3 className="topSubTitle">{this.state.subtitle}</h3>
                                    <Button varient="primary" className="topButton">{this.state.button}</Button>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </Container>
            </Fragment>
        );
    }
}

export default TopBanner;