import React, {Component,Fragment} from 'react';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {Card, Container} from "react-bootstrap";
import hennaartistryImage from "../../asset/image/portfolio/hennaartistry.png";
import krconceptImage from "../../asset/image/portfolio/krconcept.png";
import ahmshohelImage from "../../asset/image/portfolio/ahmshohel.png";
import areaenergybdImage from "../../asset/image/portfolio/areaenergybd.png";
import sixtechbdImage from "../../asset/image/portfolio/sixtechbd.png";
import thebmabdImage from "../../asset/image/portfolio/thebmabd.png";
import RestClient from "../../RestAPI/RestClient";
import AppUrl from "../../RestAPI/AppURL";

class PortfolioPage extends Component {

    constructor(){
        super();
        this.state={
            portfolioAll:[]
        }
    }

    componentDidMount() {
        RestClient.GetRequest(AppUrl.Portfolio).then(result=>{
            this.setState({portfolioAll:result});
        })
    }

    render() {

        const portfolioData = this.state.portfolioAll;
        const myView=portfolioData.map(portfolioAll=>{
            return <Col lg={4} md={6} sm={12}>
                <Card className="projectCard">
                    <Card.Img variant="top" src={portfolioAll.portfolio_image} />
                    <Card.Body>
                        <Card.Title className="projectCardTitle">{portfolioAll.portfolio_name}</Card.Title>
                        <a className="projectVisit" href={portfolioAll.portfolio_visit_link}>{portfolioAll.portfolio_visit_text}</a>
                    </Card.Body>
                </Card>
            </Col>
        })

        return (
            <Fragment>
                <Container className="text-center portfolioSection">
                    <h1 className="portfolioTitle">Portfolio</h1>
                    <p className="portfolioPara">GenCoder Do Some Great Job And The Projects Are Following Below.</p>
                    <Row>
                        {myView}
                    </Row>
                </Container>
            </Fragment>
        );
    }
}

export default PortfolioPage;