import React, {Component,Fragment} from 'react';
import '../../asset/css/custom.css';
import '../../asset/css/bootstrap.min.css';
import {Col, Container, Row} from "react-bootstrap";
import websiteFeatureImage from '../../asset/image/features/features_website.png';
import softwareFeatureImage from '../../asset/image/features/features_software.png';
import domainFeatureImage from '../../asset/image/features/features_domain.png';
import hostingFeatureImage from '../../asset/image/features/features_hosting.png';
import {BsArrowRightShort} from "react-icons/bs";
import RestClient from "../../RestAPI/RestClient";
import AppUrl from "../../RestAPI/AppURL";
import ReactHtmlParser from 'react-html-parser';

class Features extends Component {

    constructor() {
        super();
        this.state={
            featuresGen:[]
        }
    }

    componentDidMount() {
        RestClient.GetRequest(AppUrl.Features).then(result=>{
            this.setState({featuresGen:result});
        });
    }

    render() {
        const featuresList = this.state.featuresGen;
        const myView=featuresList.map(featuresGen=>{
            return <div  className="featuresMainContentArea">
                        <Row>
                            <Col lg={6} md={6} sm={12}>
                                <img className="websiteFeatureImage" src={featuresGen.features_image} />
                            </Col>

                            <Col lg={6} md={12} sm={12}>
                                <h3 className="featuresSubTitle">{featuresGen.features_title}</h3>
                                <p className="featuresSubPara">{featuresGen.features_subtitle}</p>
                                <ul className="featuresUL">
                                    {ReactHtmlParser(featuresGen.features_list)}
                                </ul>
                            </Col>
                        </Row>
                    </div>
        })

        return (
            <Fragment>
                <Container className="featuresContent">
                    <Row>
                        <h1 className="featuresTitle">Features</h1>
                        <p className="featuresPara">GenCoder is a software company working upon dynamic eye catching Websites Development, Wordpress Theme Development, Wordpress Plugin Development, Themeforest Marketplace, Custom Software Development, Domain Sell, Hosting Sell.</p>
                        <Container className="featuresMainContent">
                            <Row>
                                <Col lg={12} md={12} sm={12}>
                                        {myView}
                                </Col>
                            </Row>
                        </Container>
                    </Row>
                </Container>
            </Fragment>
        );
    }
}

export default Features;