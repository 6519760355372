import React, {Component,Fragment} from 'react';
import TopNavigation from "../components/TopNavigation/TopNavigation";
import PageTop from "../components/PageTop/PageTop";
import Team from "../components/Team/Team";
import ContactAddress from "../components/ContactAddress/ContactAddress";
import Footer from "../components/Footer/Footer";

class TeamPageDescription extends Component {

    componentDidMount() {
        window.scroll(0,0);
    }

    render() {
        return (
            <Fragment>
                <TopNavigation title="Team"/>
                <PageTop pageTitle="GenCoder Team"/>
                <Team/>
                <ContactAddress/>
                <Footer/>
            </Fragment>
        );
    }
}

export default TeamPageDescription;