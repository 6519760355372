
class AppUrl {
    static BaseUrl = 'https://siteapi.gencoderbd.com';

    static TopBanner = this.BaseUrl+'/TopBanner';
    static About = this.BaseUrl+'/About';
    static AboutPage = this.BaseUrl+'/AboutPage';
    static Language = this.BaseUrl+'/Language';
    static Features = this.BaseUrl+'/Features';
    static Services = this.BaseUrl+'/Services';
    static ServicesPage = this.BaseUrl+'/ServicesPage';
    static Portfolio = this.BaseUrl+'/Portfolio';
    static PortfolioPage = this.BaseUrl+'/PortfolioPage';
    static Testimonial = this.BaseUrl+'/Testimonial';
    static Team = this.BaseUrl+'/Team';
    static TeamPage = this.BaseUrl+'/TeamPage';
    static ContactAddress = this.BaseUrl+'/ContactAddress';
    static ContactForm = this.BaseUrl+'/ContactForm';
    static ContactFormPage = this.BaseUrl+'/ContactFormPage';
    static Footer = this.BaseUrl+'/Footer';
}

export default AppUrl;