import React, {Component,Fragment} from 'react';
import Container from "react-bootstrap/Container";
import {Col, Row} from "react-bootstrap";
import {BsAt, BsSnow, BsWifi, BsXDiamond} from "react-icons/bs";
import RestClient from "../../RestAPI/RestClient";
import AppUrl from "../../RestAPI/AppURL";

class Services extends Component {

    constructor(){
        super();
        this.state={
            allServices:[]
        }
    }

    componentDidMount() {
        RestClient.GetRequest(AppUrl.Services).then(result=>{
            this.setState({allServices:result});
        })
    }

    render() {
        const allServicesData = this.state.allServices;
        const myView=allServicesData.map(allServices=>{
            return <Col lg={3} md={6} sm={12}>
                        <div  className="servicesIconBox">
                            <div className="servicesIconArea"> <span className="servicesIcon"> <BsAt/> </span>  </div>
                            <h4 className="servicesBoxTitle"><a href="">{allServices.services_title}</a></h4>
                            <p className="servicesBoxDescription">{allServices.services_description}</p>
                        </div>
                    </Col>
        })
        return (
            <Fragment>
                <Container fluid={true} className="servicesSection">

                        <h1 className="servicesTitle">Services</h1>
                        <p className="servicesPara">GenCoder is a software company working upon dynamic eye catching Websites Development, Wordpress Theme Development, Wordpress Plugin Development, Themeforest Marketplace, Custom Software Development, Domain Sell, Hosting Sell.</p>
                        <Container fluid={false} className="servicesContent">
                            <Row>
                                {myView}
                            </Row>
                        </Container>

                </Container>
            </Fragment>
        );
    }
}

export default Services;