import React, {Component,Fragment} from 'react';
import {Col, Container, Row} from "react-bootstrap";

import {BsArrowRightShort, BsSkype} from "react-icons/bs";
import {BsFacebook, BsInstagram, BsLinkedin, BsTwitter} from "react-icons/all";
import RestClient from "../../RestAPI/RestClient";
import AppUrl from "../../RestAPI/AppURL";

import ReactHtmlParser from 'react-html-parser';

class Footer extends Component {

    constructor() {
        super();
        this.state={
            companyName:'',
            companyAddress:'',
            footerTwoTitle:'',
            footerTwoContent:'',
            footerThreeTitle:'',
            footerThreeContent:'',
            footerFourTitle:'',
            footerFourContent:'',
            footerCopyRightContent:'',
        }
    }

    componentDidMount() {
        RestClient.GetRequest(AppUrl.Footer).then(result=>{
            this.setState({
                companyName:result[0]['footer_one_title'],
                companyAddress:result[0]['footer_one_content'],
                footerTwoTitle:result[0]['footer_two_title'],
                footerTwoContent:result[0]['footer_two_content'],
                footerThreeTitle:result[0]['footer_three_title'],
                footerThreeContent:result[0]['footer_three_content'],
                footerFourTitle:result[0]['footer_four_title'],
                footerFourContent:result[0]['footer_four_content'],
                footerCopyRightContent:result[0]['footer_copyright_content'],
            });
        })
    }

    render() {
        return (
            <Fragment>
                <Container fluid={true} className="footerSection">
                    <Container>
                        <Row>
                            <Col lg={3} md={6} sm={12}>
                                <h3 className="footerGenCoderTitle">{this.state.companyName}</h3>
                                <p className="footerPara">{ReactHtmlParser(this.state.companyAddress)}</p>
                            </Col>
                            <Col lg={3} md={6} sm={12}>
                                <h3 className="footerTitle">{this.state.footerTwoTitle}</h3>
                                <p className="footerPara">
                                    <ul className="footerUL">
                                        {ReactHtmlParser(this.state.footerTwoContent)}
                                    </ul>
                                </p>
                            </Col>
                            <Col lg={3} md={6} sm={12}>
                                <h3 className="footerTitle">{this.state.footerThreeTitle}</h3>
                                <p className="footerPara">
                                    <ul className="footerUL">
                                        {ReactHtmlParser(this.state.footerThreeContent)}
                                    </ul>
                                </p>
                            </Col>
                            <Col lg={3} md={6} sm={12}>
                                <h3 className="footerTitle">{this.state.footerFourTitle}</h3>
                                <div className="social-links">
                                    <a href="#" className="twitter"><BsTwitter/></a>
                                    <a href="#" className="facebook"><BsFacebook/></a>
                                    <a href="#" className="instagram"><BsInstagram/></a>
                                    <a href="#" className="skype"><BsSkype/></a>
                                    <a href="#" className="linkedin"><BsLinkedin/></a>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </Container>
                <Container fluid={true} className="footerCopyrightSection text-center">
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className="footerCopyrightContent text-center">
                                {ReactHtmlParser(this.state.footerCopyRightContent)}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        );
    }
}

export default Footer;

<div className="social-links text-center text-md-right pt-3 pt-md-0">
    <a href="#" className="twitter"><i className="bx bxl-twitter"></i></a>
    <a href="#" className="facebook"><i className="bx bxl-facebook"></i></a>
    <a href="#" className="instagram"><i className="bx bxl-instagram"></i></a>
    <a href="#" className="google-plus"><i className="bx bxl-skype"></i></a>
    <a href="#" className="linkedin"><i className="bx bxl-linkedin"></i></a>
</div>