import React, {Component,Fragment} from 'react';
import {Button, Col, Container, Form, Row} from "react-bootstrap";
import {BsEnvelope, BsPinMap, BsTelephoneInbound} from "react-icons/all";
import {BsArrowRightShort} from "react-icons/bs";
import RestClient from "../../RestAPI/RestClient";
import AppUrl from "../../RestAPI/AppURL";

import ReactHtmlParser from 'react-html-parser';

class Contact extends Component {

    constructor(){
        super();
        this.state={
            addtitle:'',
            adddetails:'',
            emailtitle:'',
            emaildetails:'',
            phonetitle:'',
            phonedetails:''
        }
    }

    componentDidMount() {
        RestClient.GetRequest(AppUrl.ContactAddress).then(result=>{
            this.setState({
                addtitle:result[0]['contact_address_title'],
                adddetails:result[0]['contact_address_details'],
                emailtitle:result[0]['contact_email_title'],
                emaildetails:result[0]['contact_email_details'],
                phonetitle:result[0]['contact_phone_title'],
                phonedetails:result[0]['contact_phone_details']
            })
        })
    }



    sendContact(){
        let name = document.getElementById("name").value;
        let email = document.getElementById("email").value;
        let phone = document.getElementById("phone").value;
        let message = document.getElementById("message").value;

        let jsonObject = {name:name,email:email,phone:phone,message:message};

        RestClient.PostRequest(AppUrl.ContactForm,JSON.stringify(jsonObject)).then(result=>{
            alert(result);
        }).catch(error=>{
            alert("Error");
        })

        //alert(name+" --- "+email+" --- "+phone+" --- "+message);

    }

    render() {

        return (
            <Fragment>
                <Container fluid={true} className="contactSection">
                    <h1 className="contactTitle">Contact</h1>
                    <p className="contactPara">GenCoder always ready to give their client better service. If users have any query about us GenCoder gives high priority to the users to solve or hear the issues and give a feedback to the users.</p>
                    <Container className="contactContent">
                        <Row>
                            <Col lg={6} md={12} sm={12}>
                                <Row>
                                    <Col lg={12} md={12} sm={12}>
                                        <div className="officeAddress">
                                            <div className="officeAddressBox">
                                                <i className="contactLineIcon"><BsPinMap/></i>
                                                <h3 className="contactOfficeAddressTitle">{ReactHtmlParser(this.state.addtitle)}</h3>
                                                <p>{ReactHtmlParser(this.state.adddetails)}</p>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={6} md={6} sm={12} className="">
                                        <div className="officeEmail">
                                            <div className="officeEmailBox">
                                                <i className="contactLineIcon"><BsEnvelope/></i>
                                                <h3 className="contactOfficeEmailTitle">{this.state.emailtitle}</h3>
                                                <p>{ReactHtmlParser(this.state.emaildetails)}</p>
                                                <br></br><br></br>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col lg={6} md={6} sm={12} className="">
                                        <div className="officePhone">
                                            <div className="officePhoneBox">
                                                <i className="contactLineIcon"><BsTelephoneInbound/></i>
                                                <h3 className="contactOfficePhoneTitle">{this.state.phonetitle}</h3>
                                                <p>{ReactHtmlParser(this.state.phonedetails)}</p>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                            <Col lg={6} md={12} sm={12}>
                                <Row>
                                    <Col lg={12} md={12} sm={12}>
                                        <div className="officeContactForm">
                                            <div className="officeContactFormBox">
                                                <Form>
                                                    <Form.Group className="mb-3" controlId="formName">
                                                        <Form.Control id="name" type="text" placeholder="Your Name" />
                                                    </Form.Group>

                                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                                        <Form.Control id="email" type="email" placeholder="Your Email" />
                                                    </Form.Group>

                                                    <Form.Group className="mb-3" controlId="formPhone">
                                                        <Form.Control id="phone" type="text" placeholder="Your Phone" />
                                                    </Form.Group>

                                                    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                                        <Form.Control id="message" as="textarea" rows={7} placeholder="Your Message" />
                                                    </Form.Group>

                                                    <Button variant="primary" onClick={this.sendContact}>
                                                        Submit
                                                    </Button>
                                                </Form>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </Container>
            </Fragment>
        );
    }
}

export default Contact;