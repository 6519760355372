import React, {Component,Fragment} from 'react';
import TopNavigation from "../components/TopNavigation/TopNavigation";
import PageTop from "../components/PageTop/PageTop";
import PortfolioPage from "../components/PortfolioPage/PortfolioPage";
import ContactAddress from "../components/ContactAddress/ContactAddress";
import Footer from "../components/Footer/Footer";

class PortfolioPageDescription extends Component {

    componentDidMount() {
        window.scroll(0,0);
    }

    render() {
        return (
            <Fragment>
                <TopNavigation title="Portfolio"/>
                <PageTop pageTitle="Portfolio"/>
                <PortfolioPage/>
                <ContactAddress/>
                <Footer/>
            </Fragment>
        );
    }
}

export default PortfolioPageDescription;