import React, {Component,Fragment} from 'react';
import {Col, Container, Row} from "react-bootstrap";
import {BsFacebook, BsInstagram, BsLinkedin, BsTwitter} from "react-icons/all";

import ceoMostafaKamalImage from '../../asset/image/team/Mostafa_Kamal.png';
import mdMdAbdulHakimImage from '../../asset/image/team/Md_Abdul_Hakim.png';
import RestClient from "../../RestAPI/RestClient";
import AppUrl from "../../RestAPI/AppURL";


class Team extends Component {
    constructor(){
        super();
        this.state={
            teamAll:[]
        }
    }

    componentDidMount() {
        RestClient.GetRequest(AppUrl.Team).then(result=>{
            this.setState({teamAll:result});
        })
    }
    render() {
        const teamData=this.state.teamAll;
        const myView=teamData.map(teamAll=>{
            return  <Col lg={3} md={6} sm={12}>
                <div className="aos-init aos-animate" data-aos="fade-up" data-aos-delay="100">
                    <div className="teamMember">
                        <img src={teamAll.team_person_image} className="img-fluid" alt="" />
                        <div className="teamMemberInfo">
                            <h4 className="teamMemberTitle">{teamAll.team_person_name}</h4>
                            <p className="teamMemberPara">{teamAll.team_person_designation}</p>
                        </div>
                    </div>
                </div>
            </Col>
        })

        return (
            <Fragment>
                <Container className="teamSection">
                    <h1 className="teamTitle">Team</h1>
                    <p className="teamPara">GenCoder has strong Management and Development Team.</p>
                    <Row>
                        {myView}
                    </Row>
                </Container>
            </Fragment>
        );
    }
}

export default Team;