import React, {Component,Fragment} from 'react';
import TopNavigation from "../components/TopNavigation/TopNavigation";
import PageTop from "../components/PageTop/PageTop";
import Services from "../components/Services/Services";
import Footer from "../components/Footer/Footer";
import ContactAddress from "../components/ContactAddress/ContactAddress";

class ServicesPagesDescription extends Component {

    componentDidMount() {
        window.scroll(0,0);
    }

    render() {
        return (
            <Fragment>
                <TopNavigation title="Services"/>
                <PageTop pageTitle="Services"/>
                <Services/>
                <ContactAddress/>
                <Footer/>
            </Fragment>
        );
    }
}

export default ServicesPagesDescription;