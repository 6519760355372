import React, {Component} from 'react';
import TopNavigation from "../components/TopNavigation/TopNavigation";
import PageTop from "../components/PageTop/PageTop";
import ContactAddress from "../components/ContactAddress/ContactAddress";
import Footer from "../components/Footer/Footer";

class ContactPageDescription extends Component {

    componentDidMount() {
        window.scroll(0,0);
    }

    render() {
        return (
            <div>
                <TopNavigation  title="Contact"/>
                <PageTop pageTitle="Contact"/>
                <ContactAddress/>
                <Footer/>
            </div>
        );
    }
}

export default ContactPageDescription;