import React, {Component,Fragment} from 'react';
import {Col, Container, Row} from "react-bootstrap";
import marketingSalesRemoteImage from "../../asset/image/career/Sales_Executive_3.jpg";

class CareerPage extends Component {
    render() {
        return (
            <Fragment>
                <Container>
                    <Row>
                        <h3 className="careerPageTitle">
                            Career
                        </h3>
                        <p className="careerPageTitlePara">
                            Make And Upgrade Your Career With GenCoder
                        </p>
                        <div>
                            <h3 className="jobTitle">𝐉𝐨𝐛 𝐓𝐢𝐭𝐥𝐞: 𝐌𝐚𝐫𝐤𝐞𝐭𝐢𝐧𝐠 & 𝐒𝐚𝐥𝐞𝐬 𝐄𝐱𝐞𝐜𝐮𝐭𝐢𝐯𝐞 (𝐖𝐞𝐛𝐬𝐢𝐭𝐞𝐬 & 𝐒𝐨𝐟𝐭𝐰𝐚𝐫𝐞).</h3>
                            <h5><b>Salary:</b>&nbsp; 𝗬𝗼𝘂 𝗚𝗲𝘁 𝟰𝟬% 𝗼𝗳 𝗬𝗼𝘂𝗿 𝗦𝗲𝗹𝗹𝘀 𝗔𝘀 𝗬𝗼𝘂𝗿 𝗦𝗮𝗹𝗮𝗿𝘆. </h5>
                            <h6><b>(আপনার  সেল থেকে যেই পেমেন্ট আসবে তার ৪০% আপনার স্যালারি হিসাবে গণ্য হবে) </b></h6>
                            <h5>𝗬𝗼𝘂 𝗪𝗶𝗹𝗹 𝗚𝗲𝘁 𝟱𝟬% 𝗮𝘀 𝗬𝗼𝘂𝗿 𝗦𝗮𝗹𝗮𝗿𝘆 𝗜𝗳 𝗬𝗼𝘂 𝗖𝗮𝗻 𝗦𝗲𝗹𝗹 𝗢𝗻𝗲 𝗟𝗮𝗸𝗵 𝗧𝗮𝗸𝗮 𝗜𝗻 𝗮 𝗠𝗼𝗻𝘁𝗵. </h5>
                            <h6><b>এক মাসে ১ লক্ষ্য টাকার সেল করতে পারলে আপনি পাবেন পেমেন্টের ৫০% যা আপনার স্যালারি হিসাবে গণ্য হবে ।  </b></h6>
                            <h5>Anybody Can Take This Job As Part Time / Full Time </h5>
                            <h5>Works From Anywhere In Bangladesh OR Abroad </h5> <br></br>
                            𝐉𝐨𝐛 𝐓𝐲𝐩𝐞: Remote Job (From Anywhere In Bangladesh OR Abroad). <br></br>
                            𝐂𝐨𝐦𝐩𝐚𝐧𝐲 𝐍𝐚𝐦𝐞: GenCoder <br></br>
                            𝐎𝐟𝐟𝐢𝐜𝐞 𝗔𝗱𝗱𝗿𝗲𝘀𝘀: House: 10, Road: 07 (Rakhi Paribar Road), Block: E, Kalshi, Mirpur, Dhaka - 1216. <br></br>
                            𝐖𝐞𝐛𝐬𝐢𝐭𝐞: http://gencoderbd.com <br></br>
                            𝗖𝗶𝗿𝗰𝘂𝗹𝗮𝗿 𝗟𝗶𝗻𝗸: http://gencoderbd.com/career.html <br></br>
                            𝐀𝐜𝐚𝐝𝐞𝐦𝐢𝐜 𝐐𝐮𝐚𝐥𝐢𝐟𝐢𝐜𝐚𝐭𝐢𝐨𝐧: <br></br>
                            &nbsp;&nbsp; -- HSC/Degree/Hons From Any Discipline. <br></br>
                            𝐒𝐤𝐢𝐥𝐥𝐬 𝐑𝐞𝐪𝐮𝐢𝐫𝐞𝐝: Selling Websites And Software To Any Position Personal OR Corporate. <br></br>
                            𝐒𝐚𝐥𝐚𝐫𝐲: 𝗬𝗼𝘂 𝗚𝗲𝘁 𝟰𝟬% 𝗼𝗳 𝗬𝗼𝘂𝗿 𝗦𝗲𝗹𝗹𝘀 𝗔𝘀 𝗬𝗼𝘂𝗿 𝗦𝗮𝗹𝗮𝗿𝘆. <br></br>
                            <b>(আপনার  সেল থেকে যেই পেমেন্ট আসবে তার ৪০% আপনার স্যালারি হিসাবে গণ্য হবে)</b><br></br>
                            𝗬𝗼𝘂 𝗪𝗶𝗹𝗹 𝗚𝗲𝘁 𝟱𝟬% 𝗮𝘀 𝗬𝗼𝘂𝗿 𝗦𝗮𝗹𝗮𝗿𝘆 𝗜𝗳 𝗬𝗼𝘂 𝗖𝗮𝗻 𝗦𝗲𝗹𝗹 𝗢𝗻𝗲 𝗟𝗮𝗸𝗵 𝗧𝗮𝗸𝗮 𝗜𝗻 𝗮 𝗠𝗼𝗻𝘁𝗵. <br></br>
                            <b>এক মাসে ১ লক্ষ্য টাকার সেল করতে পারলে আপনি পাবেন পেমেন্টের ৫০% যা আপনার স্যালারি হিসাবে গণ্য হবে । </b><br></br>
                            𝐄𝐦𝐩𝐥𝐨𝐲𝐦𝐞𝐧𝐭 𝐒𝐭𝐚𝐭𝐮𝐬: Part Time/Full Time. <br></br>
                            𝐉𝐨𝐛 𝐑𝐞𝐬𝐩𝐨𝐧𝐬𝐢𝐛𝐢𝐥𝐢𝐭𝐢𝐞𝐬: <br></br>
                            &nbsp;&nbsp;  -- Have a proper understanding of market dynamics and customer needs of the IT products & services. <br></br>
                            &nbsp;&nbsp;  -- Have proven software sales experience, preferably in HR, Payroll, CRM, ERP <br></br>
                            &nbsp;&nbsp;  -- Have excellent presentation and communication skills. <br></br>
                            &nbsp;&nbsp;  -- Visit all types of clients for sales. <br></br>
                            &nbsp;&nbsp;  -- Plan yearly, quarterly, monthly, weekly based on sales targets and execute those efficiently. <br></br>
                            &nbsp;&nbsp;  -- Should be willing to work under deadlines and targets. <br></br>
                            &nbsp;&nbsp;  -- Should be able to write reports and proposals. <br></br>
                            &nbsp;&nbsp;  -- Set up meetings by you and you attend the meeting and get work. <br></br>
                            &nbsp;&nbsp;  -- Price Negotiation and Deal Closing by Yourself. <br></br>
                            <br></br>
                                Interested candidates are requested to send your updated Resume through email to <strong>gencoderbd@gmail.com</strong> with the job title "𝐌𝐚𝐫𝐤𝐞𝐭𝐢𝐧𝐠 & 𝐒𝐚𝐥𝐞𝐬 𝐄𝐱𝐞𝐜𝐮𝐭𝐢𝐯𝐞 (𝐖𝐞𝐛𝐬𝐢𝐭𝐞𝐬 & 𝐒𝐨𝐟𝐭𝐰𝐚𝐫𝐞)" in the subject line. <br></br>
                                𝐃𝐞𝐚𝐝𝐥𝐢𝐧𝐞: 𝟯𝟭/𝟭𝟮/𝟮𝟬𝟮𝟭 <br></br>
                                𝐂𝐨𝐧𝐭𝐚𝐜𝐭 𝐍𝐮𝐦𝐛𝐞𝐫: 01726614698, 01927747025 <br></br>
                        </div>
                        <br></br>
                        <div>
                            <img src={marketingSalesRemoteImage} className="img-fluid careerImage" alt="𝐌𝐚𝐫𝐤𝐞𝐭𝐢𝐧𝐠 & 𝐒𝐚𝐥𝐞𝐬 𝐄𝐱𝐞𝐜𝐮𝐭𝐢𝐯𝐞 (𝐖𝐞𝐛𝐬𝐢𝐭𝐞𝐬 & 𝐒𝐨𝐟𝐭𝐰𝐚𝐫𝐞)" />
                        </div>
                    </Row>
                </Container>
            </Fragment>
        );
    }
}

export default CareerPage;