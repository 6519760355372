import React, {Component,Fragment} from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import {Col, Container, Row} from "react-bootstrap";

import ahmShohelImage from '../../asset/image/testimonial/ahm_shohel.png';
import raselSikderImage from '../../asset/image/testimonial/rasel_sikder.png';
import mdAshikurRahmanImage from '../../asset/image/testimonial/md_ashikur_rahman.png';
import hennaartistryImage from "../../asset/image/testimonial/md_mahfuzur_rahman.png";
import RestClient from "../../RestAPI/RestClient";
import AppUrl from "../../RestAPI/AppURL";

class Testmonial extends Component {

    constructor(){
        super();
        this.state={
            testimonialAll:[]
        }
    }

    componentDidMount() {
        RestClient.GetRequest(AppUrl.Testimonial).then(result=>{
            this.setState({testimonialAll:result})
        })
    }

    render() {

        var settings = {
            autoplaySpeed: 5000,
            autoplay: true,
            dots: true,
            infinite: true,
            speed: 3000,
            vertical: true,
            verticalSwiping: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        initialSlide: 1
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };

        const testimonialData=this.state.testimonialAll;

        const myView=testimonialData.map(testimonialAll=>{
            return  <div>
                        <Row className="text-center">
                            <Col>
                                <img className="testmonialCircleImage" src={testimonialAll.testi_image} />
                                <h3 className="testmonialPeopleName">{testimonialAll.testi_person_name}</h3>
                                <h4 className="testmonialPeopleDesig">{testimonialAll.testi_person_designation}</h4>
                                <blockquote><p className="">{testimonialAll.testi_description}</p></blockquote>
                            </Col>
                        </Row>
                    </div>
        })

        return (
            <Fragment>
                <Container fluid={true} className="text-center testmonialSection">
                    <h1 className="testmonialTitle">Testimonial</h1>
                    <p className="testmonialPara">GenCoder get some excellent review from client.</p>
                    <Container className="testmonialContent">
                        <Slider {...settings}>

                                    {myView}

                        </Slider>
                    </Container>
                </Container>
            </Fragment>
        );
    }
}

export default Testmonial;