import React, {Component,Fragment} from 'react';
import {Col, Container, Row} from "react-bootstrap";
import htmlImage from '../../asset/image/html.png';
import cssImage from '../../asset/image/css.png';
import javaScriptImage from '../../asset/image/javascript.png';
import reactImage from '../../asset/image/react.png';
import phpImage from '../../asset/image/php.png';
import laravelImage from '../../asset/image/laravel.png';
import RestClient from "../../RestAPI/RestClient";
import AppUrl from "../../RestAPI/AppURL";

class Languages extends Component {

    constructor() {
        super();
        this.state= {
            htmlImageLanguage: [htmlImage],
            cssImageLanguage: [cssImage],
            javaScriptImageLanguage: [javaScriptImage],
            reactImageLanguage: [reactImage],
            phpImageLanguage: [phpImage],
            laravelImageLanguage: [laravelImage],

            languageImage:[]

        }
    }

    componentDidMount() {
        RestClient.GetRequest(AppUrl.Language).then(result=>{
            this.setState({languageImage:result});
        });
    }


    render() {

        const languageList = this.state.languageImage;

        const myView=languageList.map(languageImage=>{
            return <Col lg={4} md={6} sm={12}> <img className="languageImage" src={languageImage.language_image}/> </Col>
        });

        return (
            <Fragment>
                <Container fluid={true}  className="languageSection">
                    <Container>
                        <Row>
                            {myView}
                        </Row>
                    </Container>
                </Container>
            </Fragment>
        );
    }
}

export default Languages;