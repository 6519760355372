import React, {Component,Fragment} from 'react';
import {Route,Routes} from "react-router-dom";
import HomePage from "../pages/HomePage";
import AboutPageDescription from "../pages/AboutPageDescription";
import ServicesPagesDescription from "../pages/ServicesPagesDescription";
import PortfolioPageDescription from "../pages/PortfolioPageDescription";
import TeamPageDescription from "../pages/TeamPageDescription";
import CareerPageDescription from "../pages/CareerPageDescription";
import ContactPageDescription from "../pages/ContactPageDescription";

class AppRoute extends Component {
    render() {
        return (
            <Fragment>
                <Routes>
                    <Route ecact path="/" element={<HomePage/>}/>
                    <Route ecact path="/about" element={<AboutPageDescription/>}/>
                    <Route ecact path="/services" element={<ServicesPagesDescription/>}/>
                    <Route ecact path="/portfolio" element={<PortfolioPageDescription/>}/>
                    <Route ecact path="/team" element={<TeamPageDescription/>}/>
                    <Route ecact path="/career" element={<CareerPageDescription/>}/>
                    <Route ecact path="/contact" element={<ContactPageDescription/>}/>
                </Routes>
            </Fragment>
        );
    }
}

export default AppRoute;