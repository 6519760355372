import React, {Component, Fragment} from 'react';
import '../../asset/css/custom.css';
import '../../asset/css/bootstrap.min.css';
import {Col, Container, Row} from "react-bootstrap";
import {BsReceipt} from "react-icons/bs";

import ReactHtmlParser from 'react-html-parser';


import { FaConnectdevelop } from "react-icons/fa";

import AppURL from "../../RestAPI/AppURL";
import RestClient from "../../RestAPI/RestClient";

class About extends Component {

    constructor(){
        super();
        this.state={
            myAboutData:[]
        }
    }

    componentDidMount() {
        RestClient.GetRequest(AppURL.About).then(result=>{
            this.setState({myAboutData:result});
        });
    }

    render() {

        const myAboutList = this.state.myAboutData;

        const myView=myAboutList.map(myAboutData=>{
            return  <Col lg={6} md={6} sm={12}>
                        <i className="icon-boxes">  <BsReceipt/>  </i>
                        <h4 className="aboutSubjectTitle">{myAboutData.about_title}</h4>
                        <p className="aboutSubjectPara">{ ReactHtmlParser(myAboutData.about_list) }</p>
                    </Col>
        })

        return (
            <Fragment>
                <Container fluid={true} className="aboutSection">
                    <Container className="aboutContent">
                        <Row>
                            <Col lg={4} md={6} sm={12}>
                                <h1 className="aboutTitle">
                                    Eye catching Dynamic Website and Custom Software provider in Bangladesh.
                                </h1>
                                <p className="aboutSubTitlePara">
                                    ZenCoder making all kinds of websites and custom software for all kinds of users.
                                </p>
                                <a href="http://localhost:3000/about" className="aboutBtn">About us <i className="bx bx-chevron-right"></i></a>
                            </Col>
                            <Col lg={8} md={6} sm={12}>
                                <div className="" data-aos="fade-up" data-aos-delay="100">
                                    <Row>
                                        {myView}
                                    </Row>
                                </div>
                            </Col>

                        </Row>
                    </Container>
                </Container>
            </Fragment>
        );
    }
}

export default About;