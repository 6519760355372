import React, {Component, Fragment} from 'react';
import '../../asset/css/custom.css';
import '../../asset/css/bootstrap.min.css';
import {Container, Nav, Navbar} from "react-bootstrap";
import logoImage from '../../asset/image/gencoder-logo-1.png';
import logoImageScroll from '../../asset/image/gencoder-logo-2.png';
import {NavLink} from "react-router-dom";

class TopNavigation extends Component {

    constructor(props) {
        super();
        this.state={
            logoImageSite:[logoImage],
            navBarBackground:"navBarBackground",
            navItemText:"navItem",
            navVariant:"dark",
            pageTitle:props.title,
        }
    }

    onScroll=()=>{
        if(window.scrollY>100){
            this.setState({navVariant:'light',logoImageSite:[logoImageScroll],navBarBackground:"navBarBackgroundScroll",navItemText:"navItemScroll"})
        }
        else if(window.scrollY<100){
            this.setState({navVariant:'dark',logoImageSite:[logoImage],navBarBackground:"navBarBackground",navItemText:"navItem"})
        }
    }

    componentDidMount() {
        window.addEventListener('scroll',this.onScroll)
    }

    render() {
        return (
            <Fragment>
                <title>{this.state.pageTitle}</title>
                <Navbar variant={this.state.navVariant} className={this.state.navBarBackground} fixed="top" collapseOnSelect expand="lg" >
                    <Container>
                        <Navbar.Brand href="#home">
                            <img src={this.state.logoImageSite} alt="GenCoder" />
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                        <Navbar.Collapse id="responsive-navbar-nav">
                            <Nav className="me-auto">

                            </Nav>
                            <Nav>
                                <Nav.Link><NavLink className={this.state.navItemText} to="/">Home</NavLink></Nav.Link>
                                <Nav.Link><NavLink className={this.state.navItemText} to="/about">About</NavLink></Nav.Link>
                                <Nav.Link><NavLink className={this.state.navItemText} to="/services">Services</NavLink></Nav.Link>
                                <Nav.Link><NavLink className={this.state.navItemText} to="/portfolio">Portfolio</NavLink></Nav.Link>
                                <Nav.Link><NavLink className={this.state.navItemText} to="/team">Team</NavLink></Nav.Link>
                                <Nav.Link><NavLink className={this.state.navItemText} to="/career">Career</NavLink></Nav.Link>
                                <Nav.Link><NavLink className={this.state.navItemText} to="/contact">Contact</NavLink></Nav.Link>
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </Fragment>
        );
    }
}

export default TopNavigation;