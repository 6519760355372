import React, {Component,Fragment} from 'react';
import TopNavigation from "../components/TopNavigation/TopNavigation";
import TopBanner from "../components/TopBanner/TopBanner";
import About from "../components/About/About";
import Languages from "../components/Languages/Languages";
import Features from "../components/Features/Features";
import Services from "../components/Services/Services";
import PortfolioPage from "../components/PortfolioPage/PortfolioPage";
import Testmonial from "../components/Testmonial/Testmonial";
import Team from "../components/Team/Team";
import ContactAddress from "../components/ContactAddress/ContactAddress";
import Footer from "../components/Footer/Footer";

class HomePage extends Component {

    componentDidMount() {
        window.scroll(0,0);
    }

    render() {
        return (
            <Fragment>
                <TopNavigation title="Home"/>
                <TopBanner/>
                <About/>
                <Languages/>
                <Features/>
                <Services/>
                <PortfolioPage/>
                <Testmonial/>
                <Team/>
                <ContactAddress/>
                <Footer/>
            </Fragment>
        );
    }
}

export default HomePage;