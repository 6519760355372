import React, {Component,Fragment} from 'react';
import {Col, Container, Row} from "react-bootstrap";
import {BsCardChecklist, BsReceipt, BsSlack, BsUiChecksGrid} from "react-icons/bs";

class AboutPage extends Component {
    render() {
        return (
            <Fragment>
                <Container className="aboutPageContent">
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <h3 className="aboutPageTitle">
                                About Us
                            </h3>
                            <p className="aboutPageSubTitlePara">
                                ZenCoder making all kinds of websites and custom software for all kinds of users. GenCoder is a software company working upon Dynamic Websites Development, Custom Software Development, Wordpress Theme Development, Wordpress Plugin Development, Themeforest Marketplace, Domain Sell, Hosting Sell.
                            </p>
                            <p className="aboutPageSubTitlePara">
                                GenCoder was founded in 2019 for the purpose of developing affordable and quality Websites, Custom software for businesses. Located in Dhaka, the technology center of Bangladesh, our reputation for creating high-quality software solutions is well-known.
                            </p>
                            <p className="aboutPageSubTitlePara">
                                Your choice of Websites, software development And Other Services partner will determine your project’s success. GenCoder will make your experience a breeze from start to finish.
                            </p>
                            <p className="aboutPageSubTitlePara">
                                We are dedicated to your success and complete satisfaction. Our repeat customers speak volume about our service. We are fanatical about helping you create the technology solution that makes your business process easier.
                            </p>
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        );
    }
}

export default AboutPage;